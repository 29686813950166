import Vue from 'vue'
import VueRouter from 'vue-router'
import {
	SharedRoutes
} from 'shared'; // 引入共享的路由


import Home from '@/views/home/home.vue'
import login from '@/views/login/login.vue'
import register from '@/views/login/register.vue' // 注册
import registration from '@/views/login/registration.vue' // 注册协议
import disclaimer from '@/views/login/disclaimer.vue' // 免责声明
import confidentiality from '@/views/login/confidentiality.vue' // 隐私政策
import forgetPassword from '@/views/login/forgetPassword.vue' // 忘记密码
import aboutUs from '@/views/aboutUs/index.vue' //关于我们
import newsInformation from '@/views/newsInformation/index.vue' //新闻资讯
import informationDetails from '@/views/newsInformation/informationDetails.vue' //新闻资讯详情



import dataEvaluationIntroduction from '@/views/dataEvaluation/introductionPage.vue' //数据评价介绍页
import dataValuationIntroduction from '@/views/dataValuation/introductionPage.vue' //数据估值介绍页
import dataDiscoveryIntroduction from '@/views/dataDiscovery/introductionPage.vue' //数据变现介绍页
import dataCashProductDetails from '@/views/dataDiscovery/dataCashProductDetails.vue' //数据变现产品详情页
import placeOrder from '@/views/dataDiscovery/placeOrder.vue' //数据变现下单页

import myCenter from '@/views/myCenter/myCenter.vue' //个人中心
import evaluationList from '@/views/dataEvaluation/productList.vue' //个人中心(数据评价报告列表)
import valuationList from '@/views/dataValuation/productList.vue' //个人中心(数据估值报告列表)
import myOrder from '@/views/myCenter/myOrder/index.vue' //我的订单
import orderDetails from '@/views/myCenter/myOrder/orderDetails.vue' //我的订单的订单详情页
import certification from '@/views/myCenter/certification.vue' // 实名认证
import certificationAudit from '@/views/myCenter/certificationAudit.vue' // 实名认证审核状态页面
import productManagement from '@/views/myCenter/productManagement.vue' // 数据产品管理
import releaseProduct from '@/views/myCenter/releaseProduct.vue' // 数据产品管理中添加数据产品页面
import commoditiesTrading from '@/views/myCenter/transaction/commoditiesTrading.vue' // 数据产品交易
import tradingParticulars from '@/views/myCenter/transaction/tradingParticulars.vue' // 数据产品交易订单详情
import myIntegral from '@/views/myCenter/myIntegral.vue' // 我的积分
import securitySettings from '@/views/myCenter/qss/securitySettings.vue' // 安全设置
import changePassword from '@/views/myCenter/qss/changePassword.vue' // 安全设置修改密码
import modifyTel from '@/views/myCenter/qss/modifyTel.vue' // 安全设置修改手机号

import bp from '@/views/secondaryPage/bp/bp.vue' // bp嵌入式产品
import autoGeneratedBP from '@/views/secondaryPage/bp/index.vue' // bp二级页
import valuationProduct from '@/views/secondaryPage/valuation.vue' // 项目估值二级页
import diagnoseProduct from '@/views/secondaryPage/diagnose.vue' // 项目诊断二级页
import dataProduct from '@/views/secondaryPage/data.vue' // 项目数据二级页



// 数商
import numberQuotient from '@/views/numberQuotient/index.vue' // 数商测试题页面
import quotientTestResults from '@/views/numberQuotient/quotientTestResults.vue' // 数商测试结果页面
import digitalModelEvaluation from '@/views/digitalModelEvaluation/index.vue' // 数字化企业商业模式测试题页面
import businessEvaluationResults from '@/views/digitalModelEvaluation/testResults.vue' // 数字化企业商业模式测试结果页面

// 财务预计
import earlyWarningReport from '@/views/financialEarlyWarning/report.vue' //报告页面

// 初创期估值
import initialValuationReport from '@/views/valuation/initialStage/report.vue' //报告页面

// 成长期专业
import growthProfessionalReport from '@/views/valuation/growthSpecialty/report.vue' //报告页面

import refresh from '@/views/refresh/refresh.vue' //中转的空白页面


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
	...SharedRoutes,
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'Home',
		meta: {
			title: '首页',
			name: 'Home',
			setupType: 1
		},
		component: Home
	},
	{
		path: '/refresh',
		name: 'refresh',
		component: refresh,
		meta: {
			title: ''
		}
	},
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			title: '登录'
		}
	}, {
		path: '/register',
		name: 'register',
		component: register,
		meta: {
			title: "注册"
		}
	}, {
		path: '/registration',
		name: 'registration',
		component: registration,
		meta: {
			title: "注册协议"
		}
	}, {
		path: '/disclaimer',
		name: 'disclaimer',
		component: disclaimer,
		meta: {
			title: "免责声明"
		}
	}, {
		path: '/confidentiality',
		name: 'confidentiality',
		component: confidentiality,
		meta: {
			title: "隐私政策"
		}
	}, {
		path: '/forgetPassword',
		name: 'forgetPassword',
		component: forgetPassword,
		meta: {
			title: "忘记密码"
		}
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: aboutUs,
		meta: {
			title: '关于我们',
			setupType: 6
		}
	},
	{
		path: '/newsInformation',
		name: 'newsInformation',
		component: newsInformation,
		meta: {
			title: '新闻资讯',
			setupType: 5
		}
	},
	{
		path: '/informationDetails',
		name: 'informationDetails',
		component: informationDetails,
		meta: {
			title: '资讯详情',
			setupType: 5
		}
	},
	{
		path: '/numberQuotient',
		name: 'numberQuotient',
		component: numberQuotient,
		meta: {
			title: '数商测试'
		}
	},
	{
		path: '/quotientTestResults',
		name: 'quotientTestResults',
		component: quotientTestResults,
		meta: {
			title: '数商测试结果'
		}
	},
	{
		path: '/digitalModelEvaluation',
		name: 'digitalModelEvaluation',
		component: digitalModelEvaluation,
		meta: {
			title: '数字化企业商业模式测评'
		}
	},
	{
		path: '/businessEvaluationResults',
		name: 'businessEvaluationResults',
		component: businessEvaluationResults,
		meta: {
			title: '数字化企业商业模式测评结果'
		}
	},
	{
		path: '/dataEvaluationIntroduction',
		name: 'dataEvaluationIntroduction',
		component: dataEvaluationIntroduction,
		meta: {
			title: '数据评价',
			name: 'dataEvaluationIntroduction',
			setupType: 2
		}
	},
	{
		path: '/dataValuationIntroduction',
		name: 'dataValuationIntroduction',
		component: dataValuationIntroduction,
		meta: {
			title: '数据估值',
			name: 'dataValuationIntroduction',
			setupType: 3
		}
	},
	{
		path: '/dataDiscoveryIntroduction',
		name: 'dataDiscoveryIntroduction',
		component: dataDiscoveryIntroduction,
		meta: {
			title: '数据变现',
			name: 'dataDiscoveryIntroduction',
			setupType: 4
		}
	},
	{
		path: '/dataCashProductDetails',
		name: 'dataCashProductDetails',
		component: dataCashProductDetails,
		meta: {
			title: '数据变现详细信息',
			name: 'dataDiscoveryIntroduction'
		}
	},
	{
		path: '/placeOrder',
		name: 'placeOrder',
		component: placeOrder,
		meta: {
			title: '订单信息',
			name: 'dataDiscoveryIntroduction'
		}
	},
	{
		path: '/myCenter',
		name: 'myCenter',
		component: myCenter,
		meta: {
			title: '个人中心',
			name: 'myCenter'
		}
	},
	{
		path: '/evaluationList',
		name: 'evaluationList',
		component: evaluationList,
		meta: {
			title: '数据评价报告',
			name: 'evaluationList'
		}
	},
	{
		path: '/valuationList',
		name: 'valuationList',
		component: valuationList,
		meta: {
			title: '数据估值报告',
			name: 'valuationList'
		}
	},
	{
		path: '/myOrder',
		name: 'myOrder',
		component: myOrder,
		meta: {
			title: '我的订单',
			name: 'myOrder'
		}
	},
	{
		path: '/orderDetails',
		name: 'orderDetails',
		component: orderDetails,
		meta: {
			title: '订单详情',
			name: 'myOrder'
		}
	},
	{
		path: '/certification',
		name: 'certification',
		component: certification,
		meta: {
			title: '实名认证',
			name: 'certification'
		}
	},
	{
		path: '/certificationAudit',
		name: 'certificationAudit',
		component: certificationAudit,
		meta: {
			title: '实名认证',
			name: 'certification'
		}
	},
	{
		path: '/productManagement',
		name: 'productManagement',
		component: productManagement,
		meta: {
			title: '数据产品管理',
			name: 'productManagement'
		}
	},
	{
		path: '/releaseProduct',
		name: 'releaseProduct',
		component: releaseProduct,
		meta: {
			title: '发布数据产品',
			name: 'productManagement'
		}
	},
	{
		path: '/commoditiesTrading',
		name: 'commoditiesTrading',
		component: commoditiesTrading,
		meta: {
			title: '数据产品交易',
			name: 'commoditiesTrading'
		}
	},
	{
		path: '/tradingParticulars',
		name: 'tradingParticulars',
		component: tradingParticulars,
		meta: {
			title: '数据产品交易',
			name: 'commoditiesTrading'
		}
	},
	{
		path: '/myIntegral',
		name: 'myIntegral',
		component: myIntegral,
		meta: {
			title: '我的积分',
			name: 'myIntegral'
		}
	},
	{
		path: '/securitySettings',
		name: 'securitySettings',
		component: securitySettings,
		meta: {
			title: '安全设置',
			name: 'securitySettings'
		},
	},
	{
		path: '/changePassword',
		name: 'changePassword',
		component: changePassword,
		meta: {
			title: '修改密码',
			name: 'securitySettings'
		},
	},
	{
		path: '/modifyTel',
		name: 'modifyTel',
		component: modifyTel,
		meta: {
			title: '修改手机号',
			name: 'securitySettings'
		},
	},
	{
		path: '/earlyWarningReport',
		name: 'earlyWarningReport',
		component: earlyWarningReport,
		meta: {
			title: '财务预警报告',
		}
	},
	{
		path: '/initialValuationReport',
		name: 'initialValuationReport',
		component: initialValuationReport,
		meta: {
			title: '初创期估值报告',
		}
	},
	{
		path: '/growthProfessionalReport',
		name: 'growthProfessionalReport',
		component: growthProfessionalReport,
		meta: {
			title: '成长期专业估值报告',
		}
	},
	{
		path: '/valuationProduct',
		path: '/valuationProduct',
		name: 'valuationProduct',
		component: valuationProduct,
		meta: {
			title: '项目估值',
			setupType: 2,
			groupId: 1
		}
	},
	{
		path: '/diagnoseProduct',
		name: 'diagnoseProduct',
		component: diagnoseProduct,
		meta: {
			title: '项目诊断',
			setupType: 2,
			groupId: 2
		}
	},
	{
		path: '/autoGeneratedBP',
		name: 'autoGeneratedBP',
		component: autoGeneratedBP,
		meta: {
			title: '项目BP',
			setupType: 2,
			groupId: 3
		}
	},
	{
		path: '/bp',
		name: 'bp',
		component: bp,
		meta: {
			setupType: 2,
			groupId: 3
		}
	},
	{
		path: '/dataProduct',
		name: 'dataProduct',
		component: dataProduct,
		meta: {
			title: '项目数据',
			setupType: 2,
			groupId: 7
		}
	}


]

const router = new VueRouter({
	routes
})

export default router