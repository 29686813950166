import qs from 'qs'
import axios from 'axios'

import {
	mapActions,
	mapMutations
} from 'vuex'

import {
	Modal,
	Input,
	DIV,
	Switch
} from 'iview'

export default {
	data() {
		return {
			isShow: false
		}
	},
	methods: {
		...mapActions([
			'getsAvatar',

		]),
		setImg(path, fileFormat) { //色调  0：蓝色    1：绿色   2：橙色    3：红色
			if (this.$store.state.user.subjectColor.hue == 1) {
				return require('@/assets/img/' + path + 'Green.' + fileFormat)
			} else if (this.$store.state.user.subjectColor.hue == 2) {
				return require('@/assets/img/' + path + 'Orange.' + fileFormat)
			} else if (this.$store.state.user.subjectColor.hue == 3) {
				return require('@/assets/img/' + path + 'Red.' + fileFormat)
			} else {
				return require('@/assets/img/' + path + 'Blue.' + fileFormat)
			}
		},
		getInfo(setupType, groupId) {
			this.$Spin.show();
			this.$post(this.domain.dataRealization + 'pageSetup/getPageSetup', qs.stringify({
					channelCode: window.channel,
					setupType: setupType,
					groupId: groupId ? groupId : ''
				}))
				.then((res) => {
					var this_ = this
					this.pageSetup = JSON.parse(res.data.content.setupContent)

					// setTimeout(function(){
					this_.isShow = true
					this_.$Spin.hide();
					// },3000)

				});
		},
		openPdf(url) { //打开pdf
			window.open(url)
		},
		hexToRgb(hex, a) {
			// 去除 # 符号
			var color = hex.replace("#", "");

			// 分割 RGB 三原色部分
			var r = parseInt(color.substring(0, 2), 16);
			var g = parseInt(color.substring(2, 4), 16);
			var b = parseInt(color.substring(4, 6), 16);

			return `rgba(${r}, ${g}, ${b},${a})`;
		},
		handleMaxSize(file) {
			this.$Message.warning('上传的图片不能超过2M')
		},
		setUpAvatar(res) { //更改头像
			const headPortrait = this.domain.testUrl + this.domain.dataRealization + res.content.url
			this.getsAvatar({
				headPortrait
			})

			this.saveHeaderImg(res.content.url)
		},
		saveHeaderImg(imgPath) { //保存头像
			this.$post(this.domain.dataRealization + 'dataCustomer/saveHeaderImg', qs.stringify({
					headerImg: imgPath
				}))
				.then((res) => {

				});
		},
		getCaptcha(value) { //发送短信验证码
			const phone = value.trim()
			if (phone) {
				// 获取验证码
				this.$post(this.domain.dataRealization + 'secure/sendPhoneCodSecure', qs.stringify({
						phoneNum: value
					}))
					.then((res) => {
						res = res.data
						if (res.code == 200) {
							this.cooling = 60
							let coolingInter = setInterval(() => {
								if (this.cooling > 0) {
									this.cooling--
								} else {
									clearInterval(coolingInter)
								}
							}, 1000)
							// const xPhone = phone.slice(0, 3) + 'xxxx' + phone.slice(7)
							// this.$Notice.success({
							// 	title: '验证码',
							// 	desc: `验证码已发送至${xPhone}`,
							// 	duration: 5
							// })
						} else {
							this.$Message.error(res.message)
						}
					});
			} else {
				this.$Message.warning('请输入手机号')
			}

		},
		alert(text, empty, key) {
			Modal.warning({
				title: '提示',
				content: text,
				onOk: () => {
					if (empty) {
						this.authInfo[key] = ''
					}
					console.log('确认按钮被点击');
				}
			});
		},
		handleMaxSize(file) {
			this.$Message.warning('上传的图片不能超过2M')
		},
		upLogo(res, key) {
			this.data[key] = this.domain.testUrl + this.domain.dataRealization + res.content.url
		},
		escapeRegExp: function(string) { //转义特殊字符
			return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		},
		fuzzyQuery() {
			var this_ = this;
			var text = this_.escapeRegExp(this_.companyName);
			var regex = new RegExp(text)
			if (/^[\u4e00-\u9fa5]+$/.test(this_.companyName)) {
				clearTimeout(this_.inputTimer);
				this_.inputTimer = setTimeout(function() { //阻止input方法执行两次
					if (this_.companyName.length >= 4 && this_.companyName.length < 20) {
						console.log('中文输入已完成,调模糊查询')
						this_.getCompanyName(this_.companyName)
					}
				}, 100); // 设置一个适当的延迟时间

			} else {
				// 中文输入未完成
				if (this_.companyName.length == 0) {
					this_.getCompanyNameList = []
				}
			}
		},
		getCompanyName: function(name) {
			this.getCompanyNameList = []
			this.$post(this.domain.ajaxUrl + 'baseInfo/getCompanyName', qs.stringify({
					companyName: name
				}))
				.then((res) => {
					// this.getCompanyNameList = res.data.content
					res.data.content.map(el => {
						this.getCompanyNameList.push(el)
					})
				});
		},
		checkedName(name, base) {
			console.log('点击了公司列表')
			this.companyName = name
			$('#getCompanyNameList').html('');
		},
		creatIndustry(companyName) {
			var productUrl = this.setLocation('', '99')
			sessionStorage.generatebpName=companyName
			window.location.href = window.domainName + productUrl + '#/industry'

		},
		loadResources() {
			this.resources.forEach(resource => {
				if (resource.type === 'css') {
					this.loadCSS(this.domain.testUrl + resource.href);
					// this.loadCSS('http://127.0.0.1:5500/' + resource.href);
				} else if (resource.type === 'js') {
					this.loadJS(this.domain.testUrl + resource.src);
					// this.loadJS('http://127.0.0.1:5500/' + resource.src);
				}
			});
		},
		loadResources_(data) {
			data.forEach(resource => {
				if (resource.type === 'css') {
					this.loadCSS(resource.href);
				} else if (resource.type === 'js') {
					this.loadJS(resource.src);
				}
			});
		},
		loadCSS(href) {
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = href;
			link.onload = () => {
				// console.log(`CSS loaded: ${href}`);
			};
			link.onerror = () => {
				console.error(`Failed to load CSS: ${href}`);
			};
			document.head.appendChild(link);
		},
		loadJS(src) {
			const script = document.createElement('script');
			script.src = src;
			// script.async = true;
			script.onload = () => {
				// console.log(`Script loaded: ${src}`);
				// 在这里可以调用该库提供的功能
			};
			script.onerror = () => {
				console.error(`Failed to load script: ${src}`);
			};
			document.body.appendChild(script);
		},
		productEntrance(stage, type) {
			// http://tdm.flcccc.com/initialvaluation/#/
			var productUrl = this.setLocation(stage, type)



			if (stage == 2 && type == 2) {
				var path = 'growthValuationBasic'
			} else if (stage == 3 && type == 2) {
				var path = 'maturityValuationBasic'
			} else if (stage == 2 && type == 7) {
				var path = "growthDiagnosisBasic";
			} else if (stage == 3 && type == 7) {
				var path = "maturityDiagnosisBasic";
			} else if (stage == 1) {
				var path = type == 7 ? 'initialDiagnosisBasic' : "initialValuationBasic";
			} else if (type == 13) {
				var path = "dataInfoSpecialty";
			} else if (type == 14) {
				var path = "basicInformationEvaluation";
			} else if (type == 15) {
				var path = "dataValuationInfoFast";
			}


			window.location.href = window.domainName + productUrl + '#/' + path


		},
		setLocation(stage, type) {
			let str = ''
			if (type == 2) {
				str = 'proValuation/'
			} else if ((stage == 2 && type == 7) || (stage == 3 && type == 7)) {
				str = 'prodiagnosis/'
			} else if (stage == 1) {
				str = type == 7 ? 'startdiagnosis/' : 'startValuation/'
			} else if (type == 13 || type == 14 || type == 15) {
				str = 'dataValuation/'
			} else if(type == 99){
				str = 'pptist/'
			}


			return str
		},
		valuationDesHtml(stage, type) { //项目估值的产品描述
			var str = ''
			if (stage == 1) {
				str = `<p class="description">基本情况分析 / 收入明细预测 / 融资案例匹配企业风险比对 / 估值原理说明 / 未来成本规划</p>`
			} else if (stage == 2 || stage == 3) {
				str = `<p class="description">完整的财务预测 / 可比公司分析 / 公司基本情况概览 / 专业估值诊断和建议 / 相对绝对估值法相结合</p>`
			} else if (stage == 4) {
				str = `<p class="description">先例交易智能匹配 / 并购双方综合画像 / 流动性风险量化处理 / 控股权溢价科学评估</p>`
			} else if (stage == 7) {
				str = `<p class="description">经营能力分析 / 企业多维评价 / 估值建议 / 多种估值模型 / 未来年度业绩及财务预测</p>`
			} else if (type == 13) {
				str = `<p class="description">收益贡献法 / 机器深度学习估值法 / 评价指标多维度综合分析 / 数据资产未来预期收益 / 层次分析法判断数据资产贡献程度</p>`
			} else if (type == 14) {
				str = `<p class="description">基础指标 / 质量指标 / 经济指标 / 风险指标 / 成本指标 / 流通指标 / 科学计量分析 / 量化建模/ 选择不同的分析方法和模型</p>`
			} else if (type == 15) {
				str = `<p class="description">科学的评价指标多维度综合分析 / 机器深度学习估值法 / 层次分析法判断数据资产贡献程度</p>`
			} else if (type == 98) {
				str = `<p class="description">综合考虑数据成本 / 应用场景 / 同类产品 / 市场需求 / 对数据产品的价格进行精准计算</p>`
			}


			return str
		},
		diagnoseDesHtml(stage) { //项目诊断的产品描述
			var str = ''
			if (stage == 1) {
				str = `<div>
						<p class="desTitle">
							<i class="iconfont icon-weidu"></i>
							<span>五大维度评分</span>
						</p>
						<ul class="introduce">
							<li>
								<i class="iconfont icon-icon"></i>
								<span>行业竞争力</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>团队实力</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>技术水平</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>风控情况</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>产品-市场匹配度</span>
							</li>
						</ul>
						<p class="desTitle">
							<i class="iconfont icon-bumengongzijiegouduibifenxi"></i>
							<span>支出&收入预测</span>
						</p>
					</div>`
			} else if (stage == 2) {
				str = `<div>
						<p class="desTitle">
							<i class="iconfont icon-See"></i>
							<span>企业成长经营的全面分析</span>
						</p>
						<ul class="introduce">
							<li>
								<i class="iconfont icon-icon"></i>
								<span>行业竞争力</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>团队实力</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>技术水平</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>风控情况</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>产品核心竞争力</span>
							</li>
						</ul>
						<p class="desTitle">
							<i class="iconfont icon-jianyi"></i>
							<span>专业价值诊断和建议</span>
						</p>
					</div>`
			} else if (stage == 3) {
				str = `<div>
						<p class="desTitle">
							<i class="iconfont icon-caozuo-zonghezhenduanpinggu" style="font-size:21px;margin-right:5px;"></i>
							<span>全方位综合诊断</span>
						</p>
						<ul class="introduce">
							<li>
								<i class="iconfont icon-icon"></i>
								<span>行业竞争力</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>团队实力</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>技术水平</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>风控情况</span>
							</li>
							<li>
								<i class="iconfont icon-icon"></i>
								<span>产品核心竞争力</span>
							</li>
						</ul>
						<p class="desTitle">
							<i class="iconfont icon-quote"></i>
							<span>财务状况体检与预测</span>
						</p>
					</div>`
			} else if (stage == 7) {
				str = `<div>
						<ul class="introduce">
							<li style="width:100%;">
								<i class="iconfont icon-zhenduanbaogao" style="font-size:21px;margin-right:5px;position:relative;left:-2px;"></i>
								<span>七维价值诊断</span>
							</li>
							<li style="width:100%;">
								<i class="iconfont icon-jianyi"></i>
								<span>专业价值诊断和建议</span>
							</li>
							<li style="width:100%;">
								<i class="iconfont icon-See"></i>
								<span>企业成长经营的全面分析</span>
							</li>
							<li style="width:100%;">
								<i class="iconfont icon-quote"></i>
								<span>财务状况全方位体检</span>
							</li>
							<li style="width:100%;">
								<i class="iconfont icon-yejibaobiao"></i>
								<span>未来年度业绩及财务预测</span>
							</li>
						</ul>
					</div>`
			}

			return str
		},






	}
}