<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="380" arrow="never" :dots="pageSetup.newsDetails.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.newsDetails">
				<div class="banner" :style="{'background':'url('+(item.imgPath?item.imgPath:setImg('news/detBanner','jpg'))+') center center no-repeat'}">
					<div>
						<p class="pageType">{{item.productName}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<Crumbs :list="crumbsList" />
			<p class="newsTitle">{{data.title}}</p>
			<p class="newsTime">{{data.publishDate}}</p>
			<div class="detailsContent">
				<div class="textEditContent" v-html="data.content"></div>
			</div>

		</div>


		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '../../common/header/flow_header.vue'
	import Footer from '../../common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'
	import Crumbs from '@/components/crumbs/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp,
			Crumbs
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					bannerList: [],
					newsDetails: [],
					superiority: [],
					productArchitecture: {},
					reportExample: []
				},
				crumbsList: [{
					name: '首页',
					path: '/'
				}, {
					name: '新闻资讯',
					path: '/newsInformation'
				}],
				data: {}



			}
		},
		mounted() {
			this.getInfo(5)
			this.getDataInformation()
			
		},
		methods: {
			getDataInformation() {
				this.$post(this.domain.dataRealization + 'dataInformation/getDataInformation', qs.stringify({
						informationId: this.$route.query.id
					}))
					.then((res) => {
						this.data = res.data.content
						this.crumbsList.push({
							name: res.data.content.title,
							path: ''
						})
						
					});

			}

		}


	}
</script>

<style scoped lang="less">
	.newsTitle{
		font-size: 28px;
		font-weight: bold;
		color: #282828;
		text-align: center;
		margin-top: 30px;
	}
	
	.newsTime{
		font-size: 14px;
		color: #999999;
		text-align: center;
		margin: 19px auto;
	}
	
	.detailsContent{
		padding-top: 20px;
		border-top: 1px solid #BFBFBF;
		padding-left: 20px;
	}
	
</style>
