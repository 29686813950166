<template>
	<div v-if="isShow">
		<Header />
		<Carousel v-model="value" loop :height="400" arrow="never" :dots="pageSetup.bannerList.length>1?'inside':'none'">
			<CarouselItem v-for="(item,index) in pageSetup.bannerList">
				<div class="banner"
					:style="{'background':'url('+(item.imgPath?item.imgPath:setImg('secondaryPage/data/banner','jpg'))+') center center no-repeat'}">
					<div>
						<p class="projectTitle">{{item.productName}}</p>
						<p class="banner_projectDes">{{item.describe}}</p>
					</div>
				</div>
			</CarouselItem>
		</Carousel>
		<div class="mainContent">
			<div>
				<p class="moduleTitle">精选服务</p>
				<div v-if="pageSetup.productList.length==1" class="one_product">
					<div>
						<img
							:src="pageSetup.dataProduct.imgPath?pageSetup.dataProduct.imgPath:setImg('secondaryPage/data/one','jpg')" />
					</div>
					<ul>
						<li v-for="(item,i) in pageSetup.productList" :key="i">
							<p class="name">{{item.reportName}}</p>
							<div v-html="valuationDesHtml(item.reportStage,item.reportType)"></div>
							<div class="operate">
								<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">
									{{item.reportType==98?'立即填写':item.reportType==14?'立即测评':'立即估值'}}
								</button>
								<a class="buttonBorderTransparency" target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>
				</div>
				<div v-else-if="pageSetup.productList.length==3">
					<ul class="multipleProducts threeProducts">
						<li v-for="(item,i) in pageSetup.productList" :key="i"
							:style="{'background':'url('+setImg('secondaryPage/data/three'+(i+1),'jpg')+') center center no-repeat'}">
							<p class="name">{{item.reportName}}</p>
							<div v-html="valuationDesHtml(item.reportStage,item.reportType)"></div>
							<div class="operate">
								<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">
									{{item.reportType==98?'立即填写':item.reportType==14?'立即测评':'立即估值'}}
								</button>
								<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
							</div>
						</li>
					</ul>

				</div>
				<div v-else>
					<ul class="multipleProducts">
						<li v-for="(item,i) in pageSetup.productList" :key="i">
							<img :src="setImg('secondaryPage/data/four'+(i+1),'jpg')" alt="" />
							<div>
								<p class="name">{{item.reportName}}</p>
								<div v-html="valuationDesHtml(item.reportStage,item.reportType)"></div>
								<div class="operate">
									<button class="buttonFill" @click="productEntrance(item.reportStage,item.reportType)">
										{{item.reportType==98?'立即填写':item.reportType==14?'立即测评':'立即估值'}}
									</button>
									<a class="buttonBorder" target="_blank" :href="item.reportExample">查看报告示例</a>
								</div>
							</div>
						</li>
					</ul>
				</div>

			</div>

			<div>
				<p class="moduleTitle">为什么要做数据资产估值</p>
				<ul class="reason">
					<li v-for="(item,index) in pageSetup.reason"
						:style="{'borderColor':hexToRgb($store.state.user.subjectColor.mainColor, '0.3'),'background':'linear-gradient(180deg, '+hexToRgb($store.state.user.subjectColor.mainColor, '0.1')+', #FFFFFF)'}">
						<p class="serialNumber" :style="{'color':hexToRgb($store.state.user.subjectColor.mainColor, '0.1')}">
							0{{index+1}}</p>
						<img :src="item.imgPath?item.imgPath:setImg('secondaryPage/data/cause'+(index+1),'png')" />
						<p class="title">{{item.title}}</p>
						<p class="describe">{{item.describe}}</p>
					</li>
				</ul>
			</div>

			<div>
				<p class="moduleTitle">产品优势</p>
				<ul class="advantage">
					<li v-for="(item,index) in pageSetup.superiority">
						<img :src="item.imgPath?item.imgPath:setImg('secondaryPage/data/advantage'+(index+1),'png')" alt="" />
						<p>{{item.title}}</p>
						<p>{{item.describe}}</p>
					</li>
				</ul>
			</div>

		</div>



		<SignUp />
		<Footer />
	</div>
</template>

<script>
	import Header from '@/common/header/flow_header.vue'
	import Footer from '@/common/footer.vue'
	import publicMethod from '../publicMethod'
	import SignUp from '@/components/signUp/index.vue'

	import qs from 'qs'
	import axios from 'axios'

	export default {
		mixins: [publicMethod],
		components: {
			Header,
			Footer,
			SignUp
		},
		data() {
			return {
				value: 0,
				pageSetup: {
					groupId: 7,
					bannerList: [{
						imgPath: '',
						isLoading: false,
						englishName: '', // 英文名称
						productName: 'Ai数据', // 产品名称
						subheading: '', // 副标题
						describe: '企业健康程度的体检报告，综合考虑外部环境及自身因素对企业价值的影响，从行业、团队、产品、技术、风控、财务六个维度形成评价指标体系，对企业经营管理进行综合诊断，提出专业发展建议。' // 文字描述
					}],
					dataProduct: { //数据
						imgPath: 'http://tdm-admin.flcccc.com/img/cover6.e7c30c86.jpg',
						isLoading: false,
						imgLoading: false
					},
					productList: [],
					reason: [{ //属于数据估值的
						imgPath: '',
						title: '企业进行数据资产入表和数据交易的前提',
						describe: '数据资产估值为企业进行数据交易提供了参考。在数据交易市场中，数据的价值是交易的核心。通过数据资产估值，企业可以了解其数据资产的潜在价值和市场价值，从而为数据交易提供合理的定价依据。',
						isLoading: false
					}],
					superiority: [{ //属于数据评价的产品优势
						imgPath: '',
						title: '预调研采集数据',
						describe: '对文献资料、特定来源、渠道和格式的数据进行采集、整理、处理、分析，开展预调研采集数据。',
						isLoading: false
					}]

				},


			}
		},
		mounted() {
			this.getInfo(2, 7)
		},
		methods: {



		}


	}
</script>

<style scoped lang="less">
	.name {
		font-size: 20px;
		font-weight: bold;
		color: #282828;
		margin-bottom: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/deep/ .description {
		font-size: 14px;
		color: #282828;
		line-height: 22px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}

	.operate {
		display: flex;
		align-items: center;

		button,
		a {
			height: 30px;
			line-height: 30px;
			font-size: 14px;
			text-align: center;
			outline: none;
		}

		button {
			margin-right: 16px;
		}

	}

	.one_product {
		height: 238px;
		display: flex;
		gap: 20px;

		>div {
			width: 590px;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			border: 2px solid #FFFFFF;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		ul {
			li {
				width: 590px;
				height: 100%;
				box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
				border: 2px solid #FFFFFF;
				padding: 40px;
				background: #F4F7FA;

				/deep/ .description {
					margin: 18px 0 28px;
				}

				button,
				a {
					width: 245px;
					height: 36px;
					line-height: 36px;
				}

			}

		}

	}


	/deep/ .multipleProducts:not(.threeProducts) {
		>li:nth-child(odd) {
			margin-right: 20px;
		}

		>li {
			width: 590px;
			height: 206px;
			background: #F4F7FA;
			display: flex;
			align-items: center;

			img {
				width: 180px;
				height: 146px;
				object-fit: cover;
				margin-right: 29px;
			}

			>div {
				height: 100%;
				position: relative;
			}

		}

		.name {
			margin-bottom: 8px;
		}

		.description {
			width: 321px;
		}

		.operate {
			position: absolute;
			bottom: 0;
		}

	}

	/deep/ .multipleProducts {
		display: flex;
		flex-wrap: wrap;

		.name,
		.description {
			color: #282828;
		}

		>li {
			position: relative;
			background-size: cover !important;
			box-shadow: 2px 2px 13px 0px rgba(6, 76, 156, 0.23);
			border: 2px solid #FFFFFF;
			margin-bottom: 20px;
			padding: 30px;
		}

		button,
		a {
			width: 140px;
		}

	}

	/deep/ .threeProducts {

		>li:nth-child(-n+2) {
			margin-right: 20px;
		}

		>li {
			width: 386px;
			height: 222px;
			background: #F4F7FA;
			padding: 30px 20px;
		}

		.operate {
			position: absolute;
			bottom: 30px;
		}

	}


	.reason {
		display: flex;
		flex-wrap: wrap;
		margin-top: 99px;

		li:nth-child(-n+2) {
			margin-top: 0;
		}

		li {
			width: 590px;
			height: 218px;
			background: linear-gradient(180deg, #E0E6FB, #FFFFFF);
			border: 2px solid #fff;
			margin-right: 20px;
			margin-top: 73px;
			position: relative;
			padding: 10px 30px 34px;
			box-sizing: border-box;

			.serialNumber {
				// position: absolute;
				font-size: 70px;
				font-weight: bold;
				color: #D5DEFC;
			}

			.title {
				width: 67%;
				font-size: 20px;
				font-weight: bold;
				color: #282828;
				position: absolute;
				top: 65px;
				left: 30px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.describe {
				font-size: 14px;
				color: #282828;
				line-height: 24px;
				text-align: justify;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				margin-top: 10px;
			}

			>img {
				width: 149px;
				height: 163px;
				object-fit: contain;
				position: absolute;
				top: -59px;
				right: 8px;
			}


		}

		li:nth-child(2n) {
			margin-right: 0;
		}
	}
</style>